import { notifyErrors } from '@/assets/js/utils'
import axios from '@axios'

export default {
  async getAll({ tableOptions, searchQuery }) {
    let endPoint = '/merchant/merchants'
    const params = []

    if (tableOptions) {
      tableOptions.sortBy.forEach((sortBy, index) => {
        const sorting = tableOptions.sortDesc[index] === true ? 'desc' : 'asc'
        const orderParam = `order_by[${sortBy}]=${sorting}`

        params.push(orderParam)
      })

      params.push(`take=${tableOptions.itemsPerPage}`)
      params.push(`page=${tableOptions.page}`)
    }

    if (params.length > 0) {
      endPoint = `${endPoint}?${params.join('&')}`
    }

    const { status, data } = await axios.get(endPoint)

    if (status === 200) {
      return data.data
    }

    return null
  },

  async create(params) {
    const endPoint = '/merchant/merchants'

    try {
      const { status, data } = await axios.post(endPoint, params)

      if (status == 201) {
        return data.data
      }
    } catch (error) {
      notifyErrors(error)
    }

    return null
  },

  // TODO: Update merchant
  // TODO: delete merchant

  async getByUuid(uuid) {
    const endPoint = `/merchant/merchants/${uuid}`

    const { status, data } = await axios.get(endPoint)

    if (status === 200) {
      return data.data
    }

    return null
  },

}

const fetchMerchant = id => axios.get(`/merchant/merchants/${id}`)
const storeMerchant = data => axios.post('/merchant/merchants', data)
const updateMerchant = (id, data) => axios.put(`/merchant/merchants/${id}`, data)
const destroyMerchant = id => axios.delete(`/merchant/merchants/${id}`)

const updateMerchantShippingRule = (id, data) => axios.put(`/merchant/merchants/${id}/shipping-rule`, data)

const fetchShippingMethods = id => axios.get(`/merchant/merchants/${id}/shipping-methods`)
const syncShippingMethods = (id, data) => axios.post(`/merchant/merchants/${id}/shipping-methods/sync`, data)

export {
  fetchMerchant, storeMerchant, updateMerchant, destroyMerchant,

  updateMerchantShippingRule,

  fetchShippingMethods,
  syncShippingMethods,
}
