<template>
  <form @submit.prevent="handleSubmit">
    <v-container>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="form.code"
            outlined
            hide-details
            label="Code"
            :placeholder="'e.g. MIXCARE'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <translable>
            <v-tab-item
              v-for="locale in locales"
              :key="locale"
              class="pt-5"
            >
              <v-row>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="form.name[locale]"
                    outlined
                    hide-details
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <classic-editor
                    v-model="form.introduction[locale]"
                    :placeholder="'Enter introduction'"
                  >
                  </classic-editor>
                </v-col>
              </v-row>
            </v-tab-item>
          </translable>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <v-menu
            v-model="showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.joined_at"
                readonly
                v-bind="attrs"
                label="Join Date"
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.joined_at"
              color="primary"
              @input="showDatePicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          md="6"
          cols="12"
        >
          <time-zone-selector
            v-model="form.time_zone_id"
            @update="value => form.time_zone_id = value"
          ></time-zone-selector>
        </v-col>
      </v-row>

      <div class="mt-4">
        <v-btn
          type="submit"
          class="mr-4"
          color="primary"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
        <v-btn
          type="reset"
          color="secondary"
          outlined
        >
          Reset
        </v-btn>
      </div>
    </v-container>
  </form>
</template>

<script>
import { ref } from '@vue/composition-api'
import ClassicEditor from '@/components/form/ClassicEditor'
import MerchantAPI from '@/api/merchant/merchant'
import Translable from '@/components/form/Translable'
import TimeZoneSelector from '@/components/form/TimeZoneSelector'

export default {
  name: 'BasicInfo',

  components: {
    ClassicEditor,
    Translable,
    TimeZoneSelector,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const showDatePicker = ref(false)

    const locales = ref(['tc', 'sc', 'en'])

    const initialData = {
      code: null,
      name: {
        tc: null,
        sc: null,
        en: null,
      },
      introduction: {
        tc: null,
        sc: null,
        en: null,
      },
      time_zone_id: 187,

      // logo: null,
      joined_at: null,
      active: true,
    }

    const form = ref({ ...initialData })

    const handleSubmit = async () => {
      loading.value = true

      const data = await MerchantAPI.create(form.value)

      if (data !== null) {
        emit('completed', data)
      }

      loading.value = false
    }

    return {
      locales,
      loading,
      form,
      handleSubmit,
      showDatePicker,
    }
  },
}
</script>
