<template>
  <v-tabs
    id="form_tabs"
    color="deep-purple accent-4"
    right
  >
    <v-tab
      v-for="locale in locales"
      :key="locale"
    >
      <country-flag
        :country="locale"
        size="normal"
      />
    </v-tab>
    <slot></slot>
  </v-tabs>
</template>

<script>
import appStore from '@/store/app'
import { computed, defineComponent } from '@vue/composition-api'
import CountryFlag from 'vue-country-flag'

export default defineComponent({
  name: 'translable',
  components: {
    CountryFlag,
  },
  setup() {
    const mapping = {
      en: 'gb',
      tc: 'hk',
      sc: 'cn',
      ja: 'jp',

      // kr: 'kr',
    }

    const locales = computed(() => appStore.state.info.locales.map(locale => mapping[locale] || locale))

    return {
      locales,
    }
  },
})
</script>

<style lang="scss" scoped>
#form_tabs {
  &.v-tabs:not(.v-tabs--vertical) {
    box-shadow: none !important;
  }
}
</style>
