<template>
  <form @submit.prevent="handleSubmit">
    <v-container>
      <v-row
        v-for="(branch, index) in branches"
        :key="index"
      >
        <v-col v-show="index > 0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <h3>Branch: {{ index + 1 }}</h3>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="branch-block"
        >
          <v-row>
            <v-col cols="12">
              <translable>
                <v-tab-item
                  v-for="locale in locales"
                  :key="locale"
                  class="pt-5"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        id="id"
                        v-model="branch.name[locale]"
                        name="name"
                        label="Name"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        id="address"
                        v-model="branch.address[locale]"
                        name="address"
                        label="Address"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </translable>

              <district-selector
                :cities="cities"
                @changed="(id) => branch.district_id = id"
              ></district-selector>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-switch
                    v-model="branch.active"
                    :label="branch.active ? 'Active' : 'Inactive'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="text-center"
        >
          <v-icon
            color="primary"
            @click="addBranch"
          >
            {{ icons.mdiPlusCircleOutline }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            type="submit"
            class="mr-4"
            color="primary"
            :loading="loading"
            :disabled="loading"
          >
            Submit
          </v-btn>
          <v-btn
            type="reset"
            color="secondary"
            outlined
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { mdiPlusCircleOutline } from '@mdi/js'
import DistrictSelector from '@/components/form/DistrictSelector.vue'
import DistrictAPI from '@/api/district'
import BranchAPI from '@/api/merchant/branch'
import Translable from '@/components/form/Translable.vue'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  name: 'Branch',

  components: {
    LMap,
    LTileLayer,
    LMarker,
    DistrictSelector,
    Translable,
  },

  props: {
    merchantId: Number,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const locales = ref(['tc', 'sc', 'en'])

    const cities = ref([])

    const initialBranchData = Object.freeze({
      name: {
        tc: null,
        sc: null,
        en: null,
      },
      address: {
        tc: null,
        sc: null,
        en: null,
      },
      district_id: null,
      active: true,
    })

    const branches = ref([])

    // Get cities
    onMounted(async () => {
      addBranch()
      cities.value = await DistrictAPI.getCities()
    })

    const addBranch = () => {
      branches.value.push(JSON.parse(JSON.stringify(initialBranchData)))
    }

    const handleSubmit = async () => {
      loading.value = true

      // call api use props.merchantId
      // remove map object in branch
      const data = await BranchAPI.create(props.merchantId, branches.value)

      if (data !== null) {
        emit('completed', data)
      }

      loading.value = false
    }

    return {
      loading,
      locales,
      cities,
      branches,
      addBranch,
      icons: {
        mdiPlusCircleOutline,
      },
      handleSubmit,
    }
  },
}
</script>

<style lang="scss" scoped>
.branch-block {
  min-height: 300px;
}
</style>
