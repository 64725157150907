<template>
  <v-container>
    <v-stepper alt-labels class="mt-12" v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="currentStep > 1"> Basic Info </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="currentStep > 2"> Branch </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="currentStep == 3"> Completed </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
            <basic-info @completed="merchantCreated"></basic-info>
        </v-stepper-content>

        <v-stepper-content step="2">
          <branch :merchant-id="merchantId" @completed="branchCreated"></branch>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" class="text-center">
              Completed
            </v-col>

            <v-col class="text-center mt-10">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :to="{name:'list-merchants'}"
              >
              Go To Merchant list
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import BasicInfo from '@/components/merchant/forms/BasicInfo'
import Branch from '@/components/merchant/forms/Branch'

export default {
  components: {
    BasicInfo,
    Branch,
  },

  setup() {
    const currentStep = ref(1)
    const merchantId = ref(null)

    const merchantCreated = merchant => {
      currentStep.value = 2
      merchantId.value = merchant?.id
    }

    const branchCreated = () => {
      console.log('merchant created')
      currentStep.value = 3
    }

    return {
      currentStep,
      merchantId,
      merchantCreated,
      branchCreated,
    }
  },
}
</script>
