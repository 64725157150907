import { notifyErrors } from '@/assets/js/utils'
import axios from '@axios'

export default {
  async getCities() {
    try {
      const { status, data } = await axios.get(`/v1/cities`)

      if (status == 200) {
        return data.data?.records
      }
    } catch (error) {
      notifyErrors(error)
    }

    return null
  },
}
