<template>
  <div>
    <p>{{ placeholder }}</p>
    <textarea
      ref="editor"
      :value="value"
      :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>
// import editor from '@ckeditor/ckeditor5-build-classic'
import editor from 'ckeditor5-custom-build/build/ckeditor'
import UploadAdapter from './UploadAdapter'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {
          uploadUrl: 'product', // api upload post path
          postUuid: '', // post uuid to attach this upload
        }
      },
    },
  },

  mounted() {
    const uploader = editor => (editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader, this.options))

    const editorConfig = {
      extraPlugins: [uploader],
    }

    editor.create(this.$refs.editor, editorConfig).then(instance => {
      instance.model.document.on('change:data', () => {
        const data = instance.getData()
        this.$emit('input', data)
      })
    })
  },
}
</script>
