import axios from '@axios'

// --- Branch ---

const fetchBranches = queryParams => axios.get('/merchant/branches', { params: queryParams })

const storeBranch = data => axios.post('/merchant/branches', data)

const updateBranch = (id, data) => axios.put(`/merchant/branches/${id}`, data)

const destroyBranch = id => axios.delete(`/merchant/branches/${id}`)

export {
  fetchBranches,
  storeBranch,
  updateBranch,
  destroyBranch,
}
